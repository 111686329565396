import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const Quienes = (props) => (
    <Layout>
        <Helmet>
            <title>Quiénes vamos siendo? - RevelARTE</title>
            <meta name="description" content="Landing Page" />
        </Helmet>


        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Quiénes vamos siendo?</h2>
                    </header>
                    <p>Usamos esta pregunta para remarcar que somos seres en continua construcción, nos transformamos y nos re creamos en la interacción en la vida y con otrxs. En esta sección les contamos algunos de nuestros caminos y de las características que van describiendo nuestro proyecto.
</p> </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                         
                            <p>Somos Flor y Gri. Somos amigas. Psicólogas sociales. Somos quietud y movimiento, luz y sombra. Somos lo familiar y lo desconocido. Somos juego y reflexión… Somos como vos. 
</p>

<div className=" grid-wrapper">
                        <div class="col-6">
                        <header className="major">
                                <h3>FLOR</h3>
                            </header>
                            <p> Flor Giovini</p> 
                        </div>
                        <div class="col-6">
                        <header className="major">
                                <h3>GRI</h3>
                            </header>
                            <p>Grisel Pinto</p>
</div>
                            
                        </div>
                        </div>
                    </div>
                </section>
              
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                        <header className="major">
                                <h3>Logo</h3>
                            </header>
                            <p>Nuestro logo es un triángulo invertido, como una v corta con sombra y en degradé,  ya que queríamos que refleje movimiento y cambio, transformación a través de un prisma que puede mirarse diferente. <br /><br />
Muestra las diferentes síntesis entre blanco y negro, positivo y negativo. Transitar y vivir las complejidades de las contradicciones que nos habitan es parte fundamental del proceso de revelarse, que no es otro más, que el proceso de aprendizaje que nos transforma, y transforma nuestros vínculos internos. <br /><br />
El aprendizaje está mediado por los vínculos, transformarse es también cambiar estos vínculos. Crear es dejarnos permear por nuevas imágenes, emociones, sentimientos, es ver a nuestros vínculos desde otro ángulo, dándonos nueva información. El claroscuro no es más que estás múltiples posibilidades a disposición de nuestro ser. <br /><br />
</p>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Quienes